<template>
  <transition name="slide-left">
    <div class="manual-resulting-event-markets">
      <div class="manual-resulting-event-markets__header">
        <div class="manual-resulting-event-markets__header-title">
          <Heading
            as="h2"
            size="xs"
          >
            {{ eventName }}
          </Heading>
          <div class="manual-resulting-event-markets__header-section">
            <Button
              :disabled="isEveryMarketResulted"
              @click="voidAllMarkets"
            >
              Void All Markets
            </Button>
            <div
              class="manual-resulting-event-markets__header-title-close"
              @click="close"
            >
              <Icon :name="'x'" />
            </div>
          </div>
        </div>
        <div class="manual-resulting-event-markets__header-search">
          <Dropdown
            class="source-filter"
            :label="selectedSearchMarketByLabel"
          >
            <DropdownItem
              clickable
              @click="onSearchMarketByChange('market-name')"
            >
              Market name
            </DropdownItem>
            <DropdownItem
              clickable
              @click="onSearchMarketByChange('market-id')"
            >
              Market ID
            </DropdownItem>
          </Dropdown>
          <TextInput
            :model-value="searchValue"
            :placeholder="'Search markets'"
            :icon="'search'"
            @update:modelValue="onSearch"
          />
        </div>
        <div class="manual-resulting-event-markets__header-resulted-filter">
          <div class="manual-resulting-event-markets__header-resulted-filter-switch">
            <Toggle
              :model-value="unresultedToggled"
              @update:model-value="value => { unresultedToggled = value; }"
            />
          </div>
          <div class="manual-resulting-event-markets__header-resulted-filter-label">
            Unresulted only
          </div>
        </div>
      </div>
      <div
        v-if="marketsLoading"
        class="markets-loading center"
      >
        <Spinner />
      </div>
      <div
        class="manual-resulting-event-markets__wrapper"
        v-else
      >
        <div class="manual-resulting-event-markets__content">
          <div
            v-for="marketId in displayMarkets"
            :key="marketId"
            class="manual-resulting-event-markets__row"
          >
            <ManualResultingEventMarket
              :market-id="marketId"
              :selected-event="selectedEvent"
              :resulting-payload="resultingPayload"
              :is-usa-view="selectedEvent.isUsaView"
              :home-team="selectedEvent.competitors[0]"
              :away-team="selectedEvent.competitors[1]"
              @onSelectResulting="selectResulting"
            />
          </div>
          <div
            v-if="!displayMarkets.length"
            class="no-markets"
          >
            <EmptyState
              class="empty-state"
              :message="'No markets found'"
            />
          </div>
        </div>
      </div>
      <div class="manual-resulting-event-markets__footer">
        <div class="manual-resulting-event-markets__footer-audit-log-group">
          <Button
            variant="secondary"
            :icon="'external'"
            class="audit-log-button"
            @click="openAuditLog"
            v-if="auditLogEnabled"
          >
            Visit Audit log
          </Button>
          <EventDisabledIndicator v-if="isEventDisabled" />
        </div>
        <div
          :class="['manual-resulting-event-markets__footer-button', {'disabled': !resultingChanges || propagating}]"
          @click="propagateResults"
        >
          Propagate results
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import {
  computed, watch, ref, onMounted, onUnmounted,
} from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import {
  map, isEmpty, each, find, join, orderBy, isNil,
  filter, includes, toLower, reverse, split, flatMap,
  every,
} from 'lodash';
import {
  winLogicExceptions, lossLogicExceptions, voidLogicExceptions, pushLogicExceptions,
} from '@/services/helpers/manual-resulting';
import Heading from '@/components/common/Heading';
import Icon from '@/components/common/Icon';
import TextInput from '@/components/common/TextInput';
import Toggle from '@/components/common/Toggle';
import Spinner from '@/components/common/Spinner';
import EmptyState from '@/components/common/EmptyState';
import MarketMapper from '@/services/helpers/market-mapper';
import Dropdown from '@/components/common/Dropdown';
import DropdownItem from '@/components/common/DropdownItem';
import Button from '@/components/common/Button';
import { getSuperAdminData } from '@/services/helpers/super-admin';
import ManualResultingEventMarket from './ManualResultingEventMarket';
import EventDisabledIndicator from '@/components/events/EventDisabledIndicator';

export default {
  components: {
    Heading,
    Icon,
    TextInput,
    Toggle,
    Spinner,
    EmptyState,
    Dropdown,
    DropdownItem,
    Button,
    ManualResultingEventMarket,
    EventDisabledIndicator,
  },
  props: {
    selectedEvent: {
      type: Object,
      required: true,
    },
  },
  emits: ['onClose'],
  setup(props, { emit }) {
    const store = useStore();
    const router = useRouter();

    const { isSuperAdmin } = getSuperAdminData();
    const auditLogEnabled = computed(() => isSuperAdmin);

    const loadEventMarkets = () => {
      store.dispatch('loadManualResultingEvent', props.selectedEvent.eventId);
    };

    const resultingPayload = ref({});
    const searchValue = ref('');
    const unresultedToggled = ref(false);

    const eventName = computed(() => (props.selectedEvent.isUsaView ? join(reverse(split(props.selectedEvent.eventName, ' v ')), ' @ ') : props.selectedEvent.eventName));
    const isEventDisabled = computed(() => !!props.selectedEvent.limbo);
    const onSearch = (value) => {
      searchValue.value = value;
    };

    const marketsDisplayConfiguration = computed(() => orderBy(filter(store.getters.displayMarketsConfiguration,
      (market) => market.active), 'position'));

    const manualResultingEventMarkets = computed(() => store.getters.manualResultingEventMarkets);
    const isEveryMarketResulted = computed(() => every(
      manualResultingEventMarkets.value,
      (market) => !find(market.selections, (selection) => !selection.outcome || selection.outcome === 'NOT_YET_SETTLED'),
    ));
    const selectedSearchMarketBy = ref('market-name');
    const selectedSearchMarketByLabel = computed(() => {
      if (selectedSearchMarketBy.value === 'market-id') {
        return 'Market ID';
      }
      return 'Market name';
    });

    const onSearchMarketByChange = (searchBy) => {
      selectedSearchMarketBy.value = searchBy;
    };

    const markets = computed(() => {
      const unresultedOnly = unresultedToggled.value;
      if (!searchValue.value && !unresultedOnly) return manualResultingEventMarkets.value;
      return filter(manualResultingEventMarkets.value, (market) => {
        const unresultedSelections = find(market.selections, (selection) => !selection.outcome || selection.outcome === 'NOT_YET_SETTLED');
        const unresultedFilter = unresultedOnly ? !!unresultedSelections : true;

        if (selectedSearchMarketBy.value === 'market-id') {
          return unresultedFilter && (includes(toLower(market.marketId), searchValue.value));
        }

        return unresultedFilter && (includes(toLower(market.marketCode), searchValue.value)
          || includes(toLower(market.marketSummary?.playerName), searchValue.value)
          || (!isNil(market.marketType.params.TEAM) && includes(toLower(props.selectedEvent?.competitors?.[0]?.name), searchValue.value))
          || (!isNil(market.marketType.params.TEAM) && includes(toLower(props.selectedEvent?.competitors?.[1]?.name), searchValue.value))
          || includes(toLower(find(marketsDisplayConfiguration.value,
            (displayMarket) => displayMarket.marketCode === market.marketCode
              && displayMarket.selections === market.marketType.params.SELECTIONS)?.defaultName), searchValue.value));
      });
    });

    const marketDisplayConfigurations = computed(() => filter(map(marketsDisplayConfiguration.value, (marketConfiguration) => ({
      ...marketConfiguration,
      marketIds: map(MarketMapper.sortSectionMarkets(filter(markets.value, (market) => market.marketCode === marketConfiguration.marketCode
      && market.marketType.params.SELECTIONS === marketConfiguration.selections), props.isUsaView), (filterMarket) => filterMarket.marketId),
    })), (marketDisplayConfiguration) => marketDisplayConfiguration.marketIds?.length));

    const displayMarkets = computed(() => flatMap(marketDisplayConfigurations.value, (marketConfiguration) => marketConfiguration.marketIds));

    const marketsLoading = computed(() => store.getters.manualResultingEventMarketsLoading);

    const selectResulting = ({ item, market, selectionId }) => {
      const selections = resultingPayload.value[market.marketId]
        ? resultingPayload.value[market.marketId].selections
        : market.selections;
      const mappedSelections = map(selections, (selection) => {
        if (selection.selectionId === selectionId) {
          return {
            selectionId,
            outcome: item.outcome,
            resultingStatus: item.resultingStatus,
          };
        }
        if (item.outcome === 'LOSER') {
          if (market.marketType.params.SELECTIONS === 2 && !includes(lossLogicExceptions, market.marketCode)) {
            return {
              selectionId: selection.selectionId,
              outcome: 'WINNER',
              resultingStatus: item.resultingStatus,
            };
          }
          return {
            selectionId: selection.selectionId,
            outcome: selection.outcome,
            resultingStatus: selection.resultingStatus,
          };
        }
        if (item.outcome === 'WINNER') {
          if (!includes(winLogicExceptions, market.marketCode)) {
            return {
              selectionId: selection.selectionId,
              outcome: 'LOSER',
              resultingStatus: item.resultingStatus,
            };
          }
          return {
            selectionId: selection.selectionId,
            outcome: selection.outcome,
            resultingStatus: selection.resultingStatus,
          };
        }
        if (item.outcome === 'VOIDED') {
          if (!includes(voidLogicExceptions, market.marketCode)) {
            return {
              selectionId: selection.selectionId,
              outcome: 'VOIDED',
              resultingStatus: item.resultingStatus,
            };
          }
          return {
            selectionId: selection.selectionId,
            outcome: selection.outcome,
            resultingStatus: selection.resultingStatus,
          };
        }
        if (item.outcome === 'PUSHED') {
          if (!includes(pushLogicExceptions, market.marketCode)) {
            return {
              selectionId: selection.selectionId,
              outcome: 'PUSHED',
              resultingStatus: item.resultingStatus,
            };
          }
          return {
            selectionId: selection.selectionId,
            outcome: selection.outcome,
            resultingStatus: selection.resultingStatus,
          };
        }
        return {
          selectionId: selection.selectionId,
          outcome: item.outcome,
          resultingStatus: item.resultingStatus,
        };
      });
      resultingPayload.value[market.marketId] = { marketId: market.marketId, selections: mappedSelections };
    };
    const voidAllMarkets = () => {
      each(manualResultingEventMarkets.value, (market) => {
        selectResulting({
          item: {
            icon: 'credit-card-x',
            label: 'Void',
            outcome: 'VOIDED',
            resultingStatus: 'RESULTED',
          },
          market,
          selectionId: market.selections?.[0]?.selectionId || '',
        });
      });
    };

    const resultingChanges = computed(() => {
      if (isEmpty(resultingPayload.value)) return false;
      let changes = false;
      each(resultingPayload.value, (market) => {
        const foundMarket = find(manualResultingEventMarkets.value, (originalMarket) => originalMarket.marketId === market.marketId);
        if (foundMarket && foundMarket.selections[0].outcome !== market.selections[0].outcome) changes = true;
      });
      return changes;
    });

    const close = () => {
      store.dispatch('updateManualResultingEventMarkets', []);
      resultingPayload.value = {};
      emit('onClose');
    };

    const propagating = ref(false);

    const propagateResults = () => {
      if (propagating.value || !resultingChanges.value) return;
      propagating.value = true;
      // filter out markets with partially resulted selections
      const filteredPayload = filter(resultingPayload.value,
        (market) => !(find(market.selections, (selection) => selection.outcome !== 'NOT_YET_SETTLED')
          && find(market.selections, (selection) => selection.outcome === 'NOT_YET_SETTLED')));
      if (!filteredPayload.length) {
        propagating.value = false;
        resultingPayload.value = {};
        return;
      }
      const payload = map(filteredPayload, (market) => ({ marketId: market.marketId, selections: market.selections }));
      store.dispatch('resultMarkets', { eventId: props.selectedEvent.eventId, payload })
        .then(() => {
          propagating.value = false;
          resultingPayload.value = {};
          /* loadEventMarkets(); */
        })
        .catch(() => {
          propagating.value = false;
          resultingPayload.value = {};
        });
    };

    const openAuditLog = () => {
      const routeOptions = {
        name: 'audit-log',
        params: {
          eventId: props.selectedEvent.eventId,
        },
      };

      router.push(routeOptions);
    };

    onMounted(() => {
      loadEventMarkets();
      store.dispatch('subscribeToManualResultingMarkets', props.selectedEvent.eventId);
    });

    onUnmounted(() => {
      store.dispatch('unsubscribeToManualResultingMarkets', props.selectedEvent.eventId);
    });

    watch(() => props.selectedEvent.eventId, (newValue, oldValue) => {
      if (newValue !== oldValue) {
        resultingPayload.value = {};
        store.dispatch('updateManualResultingEventMarkets', []);
        loadEventMarkets();
        store.dispatch('unsubscribeToManualResultingMarkets', oldValue);
        store.dispatch('subscribeToManualResultingMarkets', newValue);
      }
    });

    return {
      close,
      selectResulting,
      resultingChanges,
      resultingPayload,
      propagateResults,
      propagating,
      searchValue,
      onSearch,
      unresultedToggled,
      marketsLoading,
      eventName,
      displayMarkets,
      selectedSearchMarketByLabel,
      onSearchMarketByChange,
      manualResultingEventMarkets,
      isEveryMarketResulted,
      voidAllMarkets,
      openAuditLog,
      auditLogEnabled,
      isEventDisabled,
    };
  },
};
</script>

<style lang="scss">
.manual-resulting-event-markets {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  right: 0;
  width: 45%;
  height: 100%;
  background: #FAFAFA;
  border-left: 2px solid #F0F0F0;
  z-index: $eventDetailsZIndex;

  .manual-resulting-event-markets__header {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #fff;
    padding: 16px 16px 0;
    position: relative;
    z-index: 98;

    .manual-resulting-event-markets__header-title {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 24px;
      margin-bottom: 12px;

      .manual-resulting-event-markets__header-title-label {
        color: #191414;
        font-weight: 600;
        font-family: 'Poppins';
        font-size: 16px;
      }

      .manual-resulting-event-markets__header-title-close {
        height: 16px;
        width: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        .icon {
          height: 16px;
          width: 16px;
          stroke: #191414;
        }
      }
    }

    .manual-resulting-event-markets__header-section {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    .manual-resulting-event-markets__header-search {
      height: 33px;
      width: 100%;
      margin-bottom: 16px;
      display: flex;
      align-items: center;
      gap: 8px;
      .text-field {
        height: 33px;
        width: 100%;
        .text-field-clear {
          width: 33px;
          height: 33px;
        }
      }
    }

    .manual-resulting-event-markets__header-resulted-filter {
      height: 65px;
      width: 100%;
      display: flex;
      align-items: center;
      padding: 0 4px;

      .manual-resulting-event-markets__header-resulted-filter-switch {
        margin-right: 4px;
      }
    }
  }

  .markets-loading {
    height: calc(100% - 69px -  166px - 66px); // navbar header height, markets header height, footer height
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .manual-resulting-event-markets__wrapper {
    flex: 1;
    height: calc(100% - 69px -  166px - 66px); // navbar header height, markets header height, footer height
    overflow-y: auto;
    background-color: #fff;
  }

  .manual-resulting-event-markets__content {
    padding: 1px 20px 20px;

    .manual-resulting-event-markets__row {
      display: flex;
      justify-content: space-between;
      min-height: 33px;
      margin-bottom: 8px;
      width: 100%;

      .market-name {
        width: 50%;
        padding-top: 6px;

        span {
          max-width: 90%;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }

      .market-selections {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
      }
    }

    .no-markets {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 400px;
      width: 100%;

      .empty-state {
        width: 400px;
      }
    }
  }

  .manual-resulting-event-markets__footer {
    width: 100%;
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    border-top: 1px solid #F0F0F0;

    .manual-resulting-event-markets__footer-audit-log-group {
      display: flex;
      gap: 8px;
    }

    .manual-resulting-event-markets__footer-button {
      height: 33px;
      background: #003C3C;
      border-radius: 4px;
      padding: 0 12px;
      color: #fff;
      display: flex;
      align-items: center;
      cursor: pointer;

      &.disabled {
        background: #F0F0F0;
        color: #A9A9A9;
      }
    }

    .audit-log-button {
      .icon svg path {
        stroke: $black;
      }
    }
  }
}
</style>
